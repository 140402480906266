// body {
//     margin: 0;
//     padding: 0;
// }

.a2a_svg{
  height:16px!important;
  width: 16px!important;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600&display=swap");

html,
body,
#root {
  font-family: "Open Sans", sans-serif;
  //position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  color: #333;
  letter-spacing: 1px;
  background: #f5f5f5;
  font-weight: 300;
}

a {
  text-decoration: none;
  color: #333;
}
.swift-links{
  color:blue!important;
  text-decoration: blue underline!important;
}

.page {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.page:nth-child(2n) {
  background: #f0f0f0;
}

.container {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.moveable {
  font-family: "Roboto", sans-serif;
  position: relative;
  width: 250px;
  height: 200px;
  text-align: center;
  font-size: 40px;
  margin: 0px auto;
  font-weight: 100;
  letter-spacing: 1px;
}

.moveable span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.description {
  text-align: center;
}

.badges {
  padding: 10px;
  text-align: center;
}

.badges img {
  height: 20px;
}

.buttons.able a {
  min-width: auto;
  padding: 8px 20px;
}

.buttons {
  text-align: center;
  margin: 0;
  padding: 10px;
}

.buttons a,
.buttons button {
  margin: 0;
  position: relative;
  text-decoration: none;
  color: #333;
  border: 1px solid #333;
  padding: 12px 30px;
  min-width: 140px;
  text-align: center;
  /* font-weight: 400; */
  display: inline-block;
  box-sizing: border-box;
  margin: 5px;
  transition: all ease 0.5s;
  font-size: 15px;
  background: transparent;
}

.buttons button:hover,
.buttons button.selected,
.buttons a:hover,
.buttons a.selected {
  background: #333;
  color: #fff;
}

.page.main {
  z-index: 1;
  min-height: 700px;
}

.label {
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 5px;
  background: #333;
  z-index: 3001;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  display: none;
  transform: translate(-100%, -100%);
}

.page.feature,
.page.footer {
  height: auto;
  text-align: left;
  padding: 60px 20px;
}

.page.feature .container,
.page.footer .container {
  top: 0;
  left: 0;
  padding: 60px 0px;
  margin: auto;
  transform: none;
  width: auto;
  max-width: 800px;
}

.page.feature .container {
  display: flex;
}

.page.footer .container {
  padding: 0px;
}

.page.feature h2.container {
  padding: 10px 0px;
  font-weight: 300;
  font-size: 40px;
}

.feature .container .left {
  position: relative;
  width: 300px;
  height: 205px;
  display: inline-block;
  vertical-align: top;
  z-index: 2000;
  margin-bottom: 20px;
}

.feature .container .right {
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 1;
}

.feature .right .description {
  text-align: left;
  margin: 0px 0px 10px;
}

.feature .right .description strong {
  font-weight: 600;
}

.draggable,
.resizable,
.scalable,
.rotatable,
.origin,
.warpable,
.pinchable {
  position: absolute;
  left: 0;
}

.origin {
  transform-origin: 30% 50%;
}

pre {
  position: relative;
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 10px;
  max-width: 500px;
}

code.hljs {
  padding: 0;
}

.tab {
  padding: 10px 12px;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 1px solid #ccc;
  box-shadow: none;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  outline: none;
}

.tab.selected {
  background: #333;
  color: #fff;
  border: 1px solid #333;
}

.panel {
  display: none;
}

.panel.selected {
  display: block;
}

.page.footer {
  font-weight: 400;
}

.page.footer a {
  text-decoration: underline;
}

.page.footer span:first-child:before {
  content: "";
}

.page.footer span:before {
  content: "/";
}

.form-title {
  margin: 0;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.25rem;

}

.form-subtitle {
  margin: 10px 8px;
  font-size: 0.875rem;
  line-height: 1.5;
  color: rgb(119, 126, 137);
  font-weight: 400;
}

.form-subtitle-link {
  margin: 0 3px;
  font-size: 0.92rem;
  line-height: 1.5;
  font-weight: 500;
  color: #307df0;

  text-decoration: none;
}

.form-label {
  margin: 15px 0 8px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;

  display: block;
}

.category-card {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  border-radius: 20px;
  text-align: center !important;
  padding: 30px 14px 24px;
  margin: 15px;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px;
}

.category-card:hover {
  cursor: pointer;
  background-color: rgb(250, 251, 251);
}

.toolbar-card {
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  border-radius: 20px;
  padding: 10px 5px;
  margin: 15px;
  box-shadow: rgb(90 114 123 / 11%) 0px 7px 30px 0px;

}

.active {
  background-color: rgba(255,255,255,0.2) !important;
  color: white !important;
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%) !important;
  font-weight: 700 !important;
}

.not-active {
  background-color: transparent !important;
  color: #9DA4AE !important;
  font-weight: 500 !important;
}

.not-active:hover {
  background-color: rgba(255, 255, 255, 0.04) !important;
  color: white !important;
}

.page-input {
  cursor: pointer;
  margin-inline: 0.5px;
  background-color: #3d424e33;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 25px;
  border: none;
  -moz-appearance: textfield;
}

.error-info {
  line-height: 1.66;
  margin: 3px 14px 0;
  font-weight: 400;
  font-size: 0.75rem;
  color: #d32f2f;
  text-align: center;
}

.topbar-background {
  background-color: #f5f5f5 !important;
  //background-image: linear-gradient(-60deg, transparent, #0ab9cf 25%, #7535e7 48%, ) !important;
  transition: opacity 1s ease, transform 7.5s ease-out, -webkit-transform 7.5s ease-out !important;
  //-webkit-mask-image: linear-gradient(90deg,transparent 0,#000 10%)!important;
}

.MuiDrawer-paperAnchorLeft {
  background-color: #1C2536 !important;

  border: none !important;
  border-right: 1px dashed lightgrey !important;
}

.history::-webkit-scrollbar {
  width: 0 !important;
  scrollbar-width: thin !important;

}


/*.book-container {
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1300px;
}

@keyframes initAnimation {
  0% {
    transform: rotateY(-15deg);
  }
  100% {
    transform: rotateY(-33deg);
  }
}

.book {
  width: 225px;
  height: 325px;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(-33deg);
  transition: 1.5s ease;
  animation: 1s ease 0s 1 initAnimation;
}*/

.template-container {
  transform-style: preserve-3d;
  transform: rotateY(-33deg);
  transition: 1.5s ease;
  animation: 1s ease 0s 1 initAnimation;
}

.template-container:hover {
  transform: rotateY(-15deg);
}

/*.book:hover {
  transform: rotateY(-15deg);
}

.book > :first-child {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  width: 225px;
  height: 325px;
  transform: translateZ(32.5px);
  background-color: #01060f;
  border-radius: 0 4px 4px 0;
  box-shadow: 5px 5px 20px #666;
}

.book::before {
  position: absolute;
  content: ' ';
  background-color: blue;
  left: 0;
  top: 8px;
  width: 63px;
  height: 309px;
  transform: translateX(184.5px) rotateY(90deg);
  background: linear-gradient(90deg,
          #fff 0%,
          #f9f9f9 5%,
          #fff 10%,
          #f9f9f9 15%,
          #fff 20%,
          #f9f9f9 25%,
          #fff 30%,
          #f9f9f9 35%,
          #fff 40%,
          #f9f9f9 45%,
          #fff 50%,
          #f9f9f9 55%,
          #fff 60%,
          #f9f9f9 65%,
          #fff 70%,
          #f9f9f9 75%,
          #fff 80%,
          #f9f9f9 85%,
          #fff 90%,
          #f9f9f9 95%,
          #fff 100%
  );
}*/

.test::before {
  position: absolute;
  content: ' ';
  background-color: blue;
  left: 0;
  top: 8px;
  width: 63px;
  height: 309px;
  transform: translateX(184.5px) rotateY(90deg);
  background: linear-gradient(90deg,
          #fff 0%,
          #f9f9f9 5%,
          #fff 10%,
          #f9f9f9 15%,
          #fff 20%,
          #f9f9f9 25%,
          #fff 30%,
          #f9f9f9 35%,
          #fff 40%,
          #f9f9f9 45%,
          #fff 50%,
          #f9f9f9 55%,
          #fff 60%,
          #f9f9f9 65%,
          #fff 70%,
          #f9f9f9 75%,
          #fff 80%,
          #f9f9f9 85%,
          #fff 90%,
          #f9f9f9 95%,
          #fff 100%
  );
}

/*.book::after {
  position: absolute;
  top: 0;
  left: 0;
  content: ' ';
  width: 225px;
  height: 325px;
  transform: translateZ(-32.5px);
  background-color: #01060f;
  border-radius: 0 4px 4px 0;
  box-shadow: -10px 0 50px 10px #666;
}*/

.template-holder {
  aspect-ratio: 1/ 1;

  height: 200px;
  background-color: white;
  border-radius: 8px;
  margin: 20px;
  cursor: pointer;

  & > img {
    border-radius: inherit;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.dashboard-card {
  background-color: rgb(255, 255, 255);
  color: rgb(17, 25, 39);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 4%) 0px 5px 22px, rgb(0 0 0 / 3%) 0px 0px 0px 0.5px
}

.dashboard-card-content {
  display: flex;
  //-webkit-box-align: center;
  //align-items: center;
  padding: 24px 32px;
}

.dashboard-content-heading {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.57;
  color: rgb(108, 115, 127);
}

.dashboard-content-value {
  margin: 0px;
  font-weight: 700;
  //font-size: 1.5rem;
  line-height: 1.2;
  color: rgb(17, 25, 39);
  font-size: 2.0833rem;
}

.dashboard-content-divider {
  margin: 0px !important;
  border-width: 0px 0px thin !important;
  border-style: solid !important;
  border-color: rgb(242, 244, 247) !important;
}

.dashboard-content-action {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 8px;

}

.new-update-container {
  display: flex;
  //flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(245, 247, 255);
  border-radius: 20px;
  padding: 32px;

}

.new-update-title {
  margin: 0px;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 2.5;
  text-transform: uppercase;
  color: rgb(99, 102, 241);

}

.new-update-main-title {
  margin: 16px 0px 0px;
  font-weight: 700;
  font-size: 1.0625rem;
  line-height: 1.2;
  color: rgb(17, 25, 39);
}

.new-update-content {
  margin: 8px 0px 0px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgb(17, 25, 39);
}

.buttonBack___1mlaL, .buttonFirst___2rhFr, .buttonLast___2yuh0, .buttonNext___2mOCa, .buttonNext___3Lm3s, .dot___3c3SI {
  cursor: pointer !important;
  border-radius: 50% !important;
  width: 8px !important;
  margin-right: 10px !important;
  height: 8px !important;
  padding: 0 !important;
  background-color: #6366F1 !important;
}

.page-header {
  margin: 0px;
  font-family: "Public Sans", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.8;
}

.page-subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.57;
  color: rgb(108, 115, 127);
}

.upload-container {
  -webkit-box-align: center;
  align-items: center;
  border: 1px dashed rgb(242, 244, 247);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  padding: 48px;
}

.upload-content-splitter {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.pdf-upload-preview {
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding: 8px 16px;
  border: 1px solid rgb(242, 244, 247);
  border-radius: 8px;
  margin-top: 20px;
}

.pdf-file-name {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.57;
  display: block;
}

.pdf-file-size {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.57;
  color: rgb(108, 115, 127);
  display: block;
}

.video-responsive iframe {
  //left: 0;
  //top: 0;
  height: 500px !important;
  width: 100%;
  //position: absolute;

}

.table-header {
  background-color: rgb(248, 249, 250) !important;
  color: rgb(47, 55, 70) !important;
  font-family: Public Sans, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  border-left: 0 !important;
}

.MuiDrawer-paper {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important;
}

.MuiDrawer-paper::-webkit-scrollbar {
  display: none !important;

}

.MuiDrawer-paper:hover::-webkit-scrollbar {
  display: block !important;
  width:9px!important;
  scrollbar-width:thin !important;
  border-radius: 6px;
  border: 0!important;
  background: #CFD8DC!important;
}

.MuiDrawer-paper::-webkit-scrollbar-thumb{
  background-color: #90A4AE !important;
  border-radius: 6px;
}

/*body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}*/

.swift-table {

  border-collapse: collapse;
  width: 100%;
}

.swift-table tr td {
  font-size: 13px !important;
  line-height: 1.7;
}

.swift-table td, .swift-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.swift-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.swift-table tr:hover {
  background-color: #ddd;
}

.swift-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: rgb(248, 249, 250) !important;
  background-color: rgb(47, 55, 70) !important;
  font-family: Public Sans, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  letter-spacing: 0.5px !important;
  border-left: 0 !important;
}

.link-download {
  padding: 5px 15px;
  border-radius: 5px;
  color: rgb(248, 249, 250);
  background-color: rgb(47, 55, 70) ;
  font-size: 12px;


}
.link-download-small{
  color:blue;
  text-decoration: underline!important;
}

.link-download:hover {
  background-color: lightskyblue !important;
  color: #333333 !important;
}

.text-edit-action {
  grid-gap: 2px;
  max-width: 75px;
  align-items: center;
  background-color: #252627;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, .1), 0 2px 12px rgba(13, 18, 22, .2);
  contain: layout;
  display: grid;
  grid-auto-flow: column;
  overflow: hidden;
  padding: 0 4px;
  border-radius: 4px;
  height: 35px;
  z-index: 9999!important;
}
.video{
  width:100%;
  height:100%;
  /* Use contain instead if you want the object to fit without cropping */
  object-fit: cover;
}

.token-steps{
  font-size: 13px;
  color:#333333;
  font-weight: 500;
  line-height: 1.7;
}

.offer-cards{
  margin-bottom: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 3px 20px rgba(0,0,0,0.2);
  padding: 10px;
}
.offer-cards h3 {
  padding: 2px 0 12px;
  font-size: 17px;
  text-align: center;
}
.offer-cards .img {
  height: 150px;
  overflow: hidden;
}
.offer-cards .btn:not([disabled]):not(.disabled) {
  cursor: pointer;
}
.offer-cards .btn-primary {
  color: #fff;
  background-color: #000!important;
  border-color: #000000!important;
  border-radius: 30px;
  margin-bottom: 10px;
  width: 100%;
  elevation: higher;
}
.offer-cards .btn, .button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  transition: all .15s ease-in-out;
  cursor: pointer;
}

.reseller-link{
  color:blue;
  font-size: 15px;
  text-decoration: underline!important;
  margin:10px 0 10px 8px!important;
}

.mockup-img {
  max-width: 500px!important;
  max-height: 500px!important;
}
.page-content-mockup{
  // display: flex!important;
  // flex-direction: column!important;
  width: 500px!important;
  // max-height: 400px!important;
  // align-items: center!important;
  margin: 0 auto!important
  // margin-top: 100px;
  // gap: 10px;
}